import { useEffect } from "react";
import { AnimatedText } from "../../components/animated-text";
import { Link, useLocation } from "react-router-dom";
import transition from "../../components/animated-text/page-animation";

import "./vacancies.scss";

function Vacancies() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="vacancies-page">
      <div className="main-title">
        <div className="line" />
        <div className="text">
          <AnimatedText text="вакансии" />
        </div>
      </div>

      <div className="vacancies-box">
        <Link to="/vacancies/hr" className="box">
          <div className="box-header">
            <div className="direction">HR</div>
            <div className="job">HR ассистент</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>

        <Link to="/vacancies/office-manager" className="box">
          <div className="box-header">
            <div className="direction">Менеджер</div>
            <div className="job">Офис-менеджер</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>

        <Link to="/vacancies/qa" className="box">
          <div className="box-header">
            <div className="direction">QA</div>
            <div className="job">QA Engineer</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>
        <Link to="/vacancies/net" className="box">
          <div className="box-header">
            <div className="direction">.NET</div>
            <div className="job">.NET-разработчик</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>

        <Link to="/vacancies/mobile" className="box">
          <div className="box-header">
            <div className="direction">Мобильный</div>
            <div className="job">Flutter разработчик</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>

        <Link to="/vacancies/marketing" className="box">
          <div className="box-header">
            <div className="direction">Маркетинг</div>
            <div className="job">Маркетолог</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>
        <Link to="/vacancies/frontend" className="box">
          <div className="box-header">
            <div className="direction">Фронтенд</div>
            <div className="job">Фронтенд разработчик</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>

        <Link to="/vacancies/layout-developer" className="box">
          <div className="box-header">
            <div className="direction">Фронтенд</div>
            <div className="job">Верстальщик</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>

        <Link to="/vacancies/pm" className="box">
          <div className="box-header">
            <div className="direction">ПМ</div>
            <div className="job">Менеджер проекта в ИТ</div>
            <div className="experience">
              Средний <span>(1–3 года)</span>
            </div>
          </div>
          <div className="box-footer">
            <img src="/assets/arrow.svg" alt="" />
            <span>Офлайн</span>
            <img src="/assets/arrow.svg" alt="" />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default transition(Vacancies);

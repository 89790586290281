import { easeIn, easeInOut, motion } from "framer-motion";

import "./page-animation.scss";

const transition = (OgComponent) => {
  return () => (
    <>
      <OgComponent />
      <motion.div
        className="slide-in"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleX: 0 }}
        transition={{ duration: 0.6, ease: easeIn, delay: 0.05 }}
      />
      <motion.div
        className="slide-in-1"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleX: 0 }}
        transition={{ duration: 0.9, ease: easeIn, delay: 0.2 }}
      />
      <motion.div
        className="slide-out"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleX: 0 }}
        transition={{
          duration: 0.9,
          ease: easeInOut,
          delay: 0.8,
        }}
      />
      <motion.div
        className="slide-out-1"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleX: 0 }}
        transition={{
          duration: 1.2,
          ease: easeInOut,
          delay: 1.1,
        }}
      />
    </>
  );
};

export default transition;

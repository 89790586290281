import { useRef } from "react";
import { useTransform, motion, useScroll } from "framer-motion";
import { Button } from "react-bootstrap";
import { AnimatedText } from "../../components/animated-text";
import chervonLeft from "../../assets/chervon-left.svg";
import chervonRight from "../../assets/chervon-right.svg";

import "./ourPortfolio.scss";

export const projects = [
  {
    title: "Orginfo",
    badge: "База Организаций",
    tags: ["Веб-разработка", "UX/UI Дизайн"],
    type: "mp4",
    url: "https://archive.org/download/orginfo/orginfo.mp4",
  },
  {
    title: "Birqadam",
    badge: "Ecommerce",
    tags: ["Мобильное Приложение", "ERP", "UX/UI Дизайн"],
    type: "webm",
    url: "https://archive.org/download/birqadam/birqadam.webm",
  },
  {
    title: "Baraka",
    badge: "Ecommerce",
    tags: ["ERP", "UX/UI Дизайн", "Аналитика"],
    type: "webm",
    url: "https://archive.org/download/baraka_202501/baraka.webm",
  },
];

function OurPortfolio() {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  return (
    <div ref={container} id="portfolio" className="our-portfolio-component">
      <div className="main-title">
        <div className="line" />
        <div className="text">
          <AnimatedText text="Портфолио" />
        </div>
      </div>

      <div>
        {projects.map((project, i) => {
          const targetScale = 1 - (projects.length - i) * 0.05;
          return (
            <Card
              key={`p_${i}`}
              i={i}
              {...project}
              progress={scrollYProgress}
              range={[i * 0.25, 1]}
              targetScale={targetScale}
            />
          );
        })}
      </div>
    </div>
  );
}

export default OurPortfolio;

const Card = ({ i, title, type, url, tags, progress, range, targetScale, badge }) => {
  const container = useRef(null);

  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <div ref={container} className="cardContainer">
      <motion.div
        style={{ background: "transparent", scale, top: `calc(-40px + ${i * 25}px)` }}
        className="card"
      >
        <div className="descriptionCard">
          <div className="tags">
            {tags?.map((item, index) => (
              <Button key={index} className="item" type="button" variant="outline-light">
                {item}
              </Button>
            ))}
          </div>
          <div className="text-flip">
            <div class="text-flip-content vertical">
              <div class="text">
                <div className="textCard">
                  <img className="icon" src={chervonLeft} alt="chervon" />
                  <h3>{badge}</h3>
                  <img className="icon" src={chervonRight} alt="chervon" />
                </div>
              </div>
              <div class="hover-text">
                <div className="textCard">
                  <img className="icon" src={chervonLeft} alt="chervon" />
                  <h3>{badge}</h3>
                  <img className="icon" src={chervonRight} alt="chervon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="titleCard">
          <h2>{title}</h2>
        </div>
        <div className="body">
          <div className="imageContainer">
            <div className="video-overlay"></div>
            <motion.div className="inner">
              <video autoPlay muted loop playsInline loading="lazy">
                <source src={url} type={`video/${type}`} />
              </video>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

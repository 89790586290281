import { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../../assets/img/brand.svg";
import "./navbar.scss";
import { Link } from "react-router-dom";

// const LANGUAGES = [
//   {
//     code: "ru",
//     label: "РУС",
//   },
//   {
//     code: "uz",
//     label: "UZ",
//   },
//   {
//     code: "en",
//     label: "ENG",
//   },
// ];

const Navbar = ({ setIsModalOpen }) => {
  const [isScrolledUp, setIsScrolledUp] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    // Navbar yuqoriga scroll qilganda ko‘rinadi, pastga scroll qilganda yo‘qoladi
    setIsScrolledUp(prevScrollPos > currentScrollPos || currentScrollPos < 24);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  //   const { t } = useTranslation();

  //   const [screenSize, setScreenSize] = useState({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   });

  //   useEffect(() => {
  //     const handleResize = () => {
  //       setScreenSize({
  //         width: Number(window.innerWidth),
  //         height: Number(window.innerHeight),
  //       });
  //     };

  //     window.addEventListener("resize", handleResize);

  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, []);

  //   const [lang_open, setLangOpen] = useState(false);

  //   const { i18n } = useTranslation();

  //   const [currentLang, setCurrentLang] = useState(i18n.language);

  //   const changeLanguage = (lang = "en") => {
  //     setCurrentLang(lang);
  //     setLangOpen(false);
  //     i18n.changeLanguage(lang);
  //   };

  //   const ref = useRef(null);

  //   const handleClickOutside = (event) => {
  //     if (lang_open === true) {
  //       // Agar tillar qismini ochuvchi tugmani bosgan bo'lsa, hech narsa qilmaslik kerak
  //       if (
  //         ref.current &&
  //         !ref.current.contains(event.target) &&
  //         event.target.id !== "language-button"
  //       ) {
  //         setLangOpen(false);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     document.addEventListener("click", handleClickOutside, true);
  //     return () => {
  //       document.removeEventListener("click", handleClickOutside, true);
  //     };
  //   });

  return (
    <>
      <Link to="/">
        <div className="mobile-logo">
          <img className="logo" src={Logo} alt="logo" />
        </div>
      </Link>
      <nav className={`Navbar ${isScrolledUp ? "scrolled-up" : "scrolled-down"}`}>
        <Link to="/" className="logo-box">
          <img onClick={() => scroll.scrollToTop()} className="desctop-logo" src={Logo} alt="" />
        </Link>
        <ul>
          <li>
            <a href="/#about_us">
              <div className="text-flip">
                <div className="text-flip-content vertical">
                  <div className="text">Почему мы?</div>
                  <div className="hover-text">Почему мы?</div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="/#portfolio">
              <div className="text-flip">
                <div className="text-flip-content vertical">
                  <div className="text">Портфолио</div>
                  <div className="hover-text">Портфолио</div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="/#services">
              <div className="text-flip">
                <div className="text-flip-content vertical">
                  <div className="text">Услуги</div>
                  <div className="hover-text">Услуги</div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="/#team">
              <div className="text-flip">
                <div className="text-flip-content vertical">
                  <div className="text">Команда</div>
                  <div className="hover-text">Команда</div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <Link to="/vacancies">
              <div className="text-flip">
                <div className="text-flip-content vertical">
                  <div className="text">Вакансии</div>
                  <div className="hover-text">Вакансии</div>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <a href="/#vlog">
              <div className="text-flip">
                <div className="text-flip-content vertical">
                  <div className="text">Влог</div>
                  <div className="hover-text">Влог</div>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <a href="tel:+998948147777">
          <button
            className="text-flip"
            type="button"
            // onClick={() => setIsModalOpen(true)}
          >
            <div class="text-flip-content vertical">
              <div class="text">Заказать проект</div>
              <div class="hover-text">Заказать проект</div>
            </div>
          </button>
        </a>
      </nav>
    </>
  );
};

export default Navbar;

import { useTranslation } from "react-i18next";
import { AnimatedTextElement, AnimatedText } from "../../components/animated-text";

import "./header.scss";

const NewHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="header-component">
      <span className="header-info-new">
        <div className="header-title">
          <AnimatedText
            threshold={0.1}
            amount={0.3}
            once={true}
            text="Инновационные решения для вашего бизнеса"
          />
        </div>
        <div className="header-text">
          <AnimatedTextElement
            delay={2}
            text="THINKLAND — IT-компания, создающая инновационные решения для бизнеса: софт, веб- и
          мобильные приложения, ИИ и автоматизация."
          />
        </div>
      </span>
    </div>
  );
};

export default NewHeader;

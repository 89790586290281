import { Link } from "react-router-dom";

import "./hamburger-icon.scss";

const HamburgerIcon = ({ menu_show, toggleMenu, white = "" }) => {
  return (
    <Link
      onClick={toggleMenu}
      className={`nohighlight ${menu_show ? "active" : ""}`}
      id="burger-icon"
      to="#"
    >
      <span className={`burger part-1 ${white}`}></span>
      <span className={`burger part-2 ${white}`}></span>
      <span className={`burger part-3 ${white}`}></span>
    </Link>
  );
};

export default HamburgerIcon;

import { useEffect, useState } from "react";
import AboutUs from "../../sections/about_us/AboutUs";
import OurServices from "../../sections/our_services/OurServices";
import OurPortfolio from "../../sections/our_portfolio/OurPortfolio";
import OurAdvantages from "../../sections/our_advantages/OurAdvantages";
import OurTeam from "../../sections/our_team/OurTeam";
import News from "../../sections/news/News";
import { Modal } from "react-bootstrap";
import NewHeader from "../../sections/header/NewHeader";
import transition from "../../components/animated-text/page-animation";
import { useModal } from "../../components/ModalProvider";
import { useLocation } from "react-router-dom";

function Home(props) {
  const location = useLocation();
  const { isModalOpen, setIsModalOpen } = useModal();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 2200);
      }
    }
  }, [location]);

  const [selectedItems, setSelectedItems] = useState([]);
  const items = [
    "Сайт",
    "Мобильное приложение",
    "Аналитика",
    "UX/UI дизайн",
    "CRM",
    "LMS",
    "ERP",
    "HRM",
    "Автоматизация",
    "Другое",
  ];

  const handleSubmit = (values) => {
    setIsModalOpen(false);
    setSelectedItems([]);
  };

  const toggleSelection = (item) => {
    setSelectedItems((prevItems) => {
      if (prevItems.includes(item)) {
        return prevItems.filter((i) => i !== item);
      } else {
        return [...prevItems, item];
      }
    });
  };

  const [phoneNumber, setPhoneNumber] = useState("+998");

  const changePhoneNumber = (e) => {
    let value = e.target.value;
    const lastLetter = value.substring(phoneNumber.length);
    if (lastLetter >= 0 && lastLetter <= 9) {
      if (phoneNumber.length <= value.length) {
        if (phoneNumber.length < 17) {
          if (
            phoneNumber.length === 4 ||
            phoneNumber.length === 7 ||
            phoneNumber.length === 11 ||
            phoneNumber.length === 14
          ) {
            setPhoneNumber(phoneNumber + " " + lastLetter);
          } else {
            setPhoneNumber(phoneNumber + lastLetter);
          }
        }
      } else {
        if (props.interval) {
          clearInterval(props.interval);
        }
        if (phoneNumber.length > 4) {
          if (
            phoneNumber.length === 6 ||
            phoneNumber.length === 9 ||
            phoneNumber.length === 13 ||
            phoneNumber.length === 16
          ) {
            setPhoneNumber(phoneNumber.substring(0, phoneNumber.length - 2));
          } else {
            setPhoneNumber(phoneNumber.substring(0, phoneNumber.length - 1));
          }
        }
      }
    }
  };

  return (
    <>
      <div className="main-component">
        <div className="row-bg-overlay"></div>
        <video autoPlay muted loop playsInline loading="lazy">
          <source src="https://archive.org/download/header_202501/header.webm" type="video/webm" />
        </video>
        <NewHeader />
      </div>
      <AboutUs />
      <OurAdvantages />
      <OurPortfolio />
      <OurServices />
      <OurTeam />
      <News />
      <Modal dialogClassName="order-modal" show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header>
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="btn-close btn-close-white"
            aria-label="Close"
          />
          <Modal.Title>Заполнить бриф</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="w-100"
            onSubmit={(e) => {
              e.preventDefault(); // Formani avtomatik submitdan to‘xtatadi
              handleSubmit(); // Submit funksiyasini chaqiradi
            }}
          >
            <div className="row align-items-center">
              {/* Имя */}
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label htmlFor="name" className="form-label">
                      Имя
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Как к вам обращаться"
                    />
                  </div>
                </div>
              </div>

              {/* Компания */}
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label htmlFor="company" className="form-label">
                      Компания
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      id="company"
                      name="company"
                      className="form-control"
                      placeholder="Название, которое говорит за вас"
                    />
                  </div>
                </div>
              </div>

              {/* Телефон */}
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label htmlFor="phone" className="form-label">
                      Телефон
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={phoneNumber}
                      onChange={changePhoneNumber}
                      className="form-control"
                      placeholder="Номер телефона для связи с вами"
                    />
                  </div>
                </div>
              </div>

              {/* Выберите услугу */}
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Выберите услугу</label>
                  </div>
                  <div className="col-md-8">
                    <div className="services-btn">
                      {items.map((item, index) => (
                        <button
                          type="button"
                          key={index}
                          className={`btn modal-btn ${
                            selectedItems.includes(item) ? "selected-item" : "default-item"
                          }`}
                          onClick={() => toggleSelection(item)}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Сообщение */}
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="description" className="form-label">
                      Сообщение
                    </label>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      id="description"
                      name="description"
                      className="form-control"
                      rows="3"
                      placeholder="Напишите, что хотите сказать"
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="col-12">
                <button type="submit" className="submit-button">
                  Отправить
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default transition(Home);

import Navbar from "../components/navbar/Navbar";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";
import Footer from "../sections/footer/Footer";
import { useModal } from "../components/ModalProvider";

function Layout({ children }) {
  const { setIsModalOpen } = useModal();

  return (
    <>
      <Navbar setIsModalOpen={setIsModalOpen} />
      <MobileNavbar setIsModalOpen={setIsModalOpen} />
      <main>{children}</main>
      <Footer setIsModalOpen={setIsModalOpen} />
    </>
  );
}

export default Layout;

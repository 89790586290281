import Marquee from "react-fast-marquee";
import "./ourServices.scss";
import { AnimatedText } from "../../components/animated-text";

function OurServices(props) {
  const items1 = [
    "Разработка",
    "Мобильная разработка",
    "UX / UI design",
    "Animations",
    "Big Data",
    "Кибербезопасность",
    "Аналитика",
  ];

  const items2 = [
    "Big Data",
    "Мобильная разработка",
    "UX / UI design",
    "Разработка",
    "Аналитика",
    "Animations",
    "Кибербезопасность",
  ];
  return (
    <div id="services" className="our-services-component">
      <div className="main-title">
        <div className="line" />
        <div className="text">
          <AnimatedText text="Услуги" />
        </div>
      </div>

      <div className="carousel-container">
        <Marquee speed={30} loop={0} pauseOnHover={true} autoFill>
          {items1.map((item, index) => (
            <div className="carousel-elements" key={index}>
              {item}
            </div>
          ))}
        </Marquee>
        <Marquee speed={30} direction="right" pauseOnHover={true} loop={0} autoFill>
          {items2.map((item, index) => (
            <div className="carousel-elements" key={index}>
              {item}
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
}

export default OurServices;

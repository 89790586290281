import { useEffect } from "react";
import { AnimatedText } from "../../components/animated-text";
import { Link, useLocation, useParams } from "react-router-dom";
import transition from "../../components/animated-text/page-animation";

import "./vacancies.scss";

function SingleVacancy() {
  const { key } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const data = [
    {
      key: "hr",
      title: "HR ассистент",
      experience: "1–3 года",
      employment: "Полная",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info:
        "Мы в поиске организованного и проактивного HR-ассистента для нашей команды!\n" +
        "Эта роль включает в себя поддержку HR-процессов, обеспечение эффективной коммуникации с сотрудниками и помощь в ежедневных административных задачах. Если вам нравится работа с людьми и вы готовы развиваться в динамичной среде, будем рады познакомиться с вами!  ",
      responsibilities: [
        "- Участие в процессе подбора персонала: размещение вакансий, отбор резюме, организация собеседований.  ",
        "- Подготовка и обновление документации сотрудников, включая оформление приёма и увольнения.  ",
        "- Ведение учёта посещаемости и помощь в подготовке расчётов для зарплаты.  ",
        "- Организация тренингов и программ развития сотрудников.",
        "- Консультирование сотрудников по вопросам HR-политик и процедур.  ",
        "- Организация тимбилдингов и корпоративных мероприятий.",
        "- Обеспечение конфиденциальности информации и соблюдение корпоративных ценностей.  ",
      ],
      requirements: [
        "- Диплом бакалавра в области управления персоналом, делового администрирования или смежной сферы (или обучение по данным направлениям).  ",
        "- Отличные навыки организации и управления временем.  ",
        "- Развитые устные и письменные коммуникативные навыки.  ",
        "- Владение MS Office (Word, Excel, PowerPoint).  ",
        "- Внимание к деталям и проактивный подход к работе.  ",
        "- Преимуществом будет опыт работы в HR или прохождение стажировки.  ",
      ],
      we_offer: [
        "- Конкурентоспособную заработную плату (обсуждается индивидуально).  ",
        "- Офис в центре города.  ",
        "- График работы: с понедельника по пятницу, с 9:00 до 18:00.  ",
        "- Наставничество и профессиональное развитие от команды руководителей и специалистов.  ",
        "- Перспективы карьерного роста.  ",
        "- Уютный и светлый офис.  ",
      ],
      description: "Присоединяйтесь к нам и помогите создать сильную и сплочённую команду!",
    },
    {
      key: "office-manager",
      title: "Офис-менеджер",
      experience: "1–3 года",
      employment: "Полная",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info:
        "Мы ищем организованного и ответственного Офис-менеджера в нашу команду!  \n" +
        "Эта роль включает в себя обеспечение бесперебойной работы офиса, координацию административных процессов и поддержку команды. Если вы внимательны к деталям, обладаете отличными организационными навыками и любите поддерживать порядок, будем рады видеть вас в нашей команде!  ",
      responsibilities: [
        "- Организация и контроль работы офиса, поддержание порядка и удобства.  ",
        "- Ведение делопроизводства, учет и хранение документации.  ",
        "- Приём и распределение звонков, обработка входящей и исходящей корреспонденции.  ",
        "- Координация встреч и переговоров, бронирование переговорных комнат.  ",
        "- Закупка канцелярских товаров и офисных принадлежностей.  ",
        "- Взаимодействие с поставщиками, курьерами и службами доставки.",
        "- Организация командировок, бронирование билетов и гостиниц.  ",
        "- Участие в подготовке и проведении корпоративных мероприятий и тимбилдингов.  ",
        "- Выполнение поручений руководителя.  ",
      ],
      requirements: [
        "- Диплом бакалавра в области делового администрирования, управления или смежной сфере (или обучение по данным направлениям).  ",
        "- Опыт работы в административной должности будет преимуществом.  ",
        "- Высокие организаторские и коммуникативные навыки.  ",
        "- Владение MS Office (Word, Excel, PowerPoint).  ",
        "- Ответственность, внимательность и умение расставлять приоритеты.  ",
        "- Проактивный подход к работе и умение решать задачи в сжатые сроки.  ",
        "- Готовность работать в динамичной среде.  ",
      ],
      we_offer: [
        "- Конкурентоспособную заработную плату (обсуждается индивидуально).  ",
        "- Офис в центре города.  ",
        "- График работы: с понедельника по пятницу, с 9:00 до 18:00.  ",
        "- Наставничество и профессиональное развитие от команды руководителей и специалистов.  ",
        "- Перспективы карьерного роста.  ",
        "- Уютный и светлый офис.  ",
      ],
      description: "Присоединяйтесь к нам и помогите создать сильную и сплочённую команду!",
    },
    {
      key: "qa",
      title: "QA Engineer ",
      experience: "1–3 года",
      employment: "Полная, полный рабочий день",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info:
        "Мы в поиске организованного и проактивного HR-ассистента для нашей команды!\n" +
        "Эта роль включает в себя поддержку HR-процессов, обеспечение эффективной коммуникации с сотрудниками и помощь в ежедневных административных задачах. Если вам нравится работа с людьми и вы готовы развиваться в динамичной среде, будем рады познакомиться с вами!  ",
      responsibilities: [
        "- Планирование и разработка тест-кейсов в соответствии с требованиями продукта.  ",
        "- Проведение функционального, регрессионного и интеграционного тестирования.  ",
        "- Автоматизация тестирования с использованием современных инструментов.  ",
        "- Выявление и отслеживание дефектов в системе баг-трекинга.  ",
        "- Взаимодействие с разработчиками и аналитиками для обеспечения высокого качества релизов.  ",
      ],
      requirements: [
        "- Опыт работы в QA не менее 2 лет.  ",
        "- Знание методологий тестирования и жизненного цикла разработки ПО.  ",
        "- Навыки написания тест-кейсов и тест-планов.",
        "- Понимание принципов автоматизации тестирования и опыт работы с инструментами, такими как Selenium, Appium, JUnit, TestNG.  ",
        "- Знание REST API, HTTP и клиент-серверной архитектуры.  ",
        "- Опыт работы с Postman и Jira.  ",
        "- Владение узбекским языком, знание русского и английского будет преимуществом.  ",
      ],
      we_offer: [
        "- Конкурентоспособную заработную плату (обсуждается индивидуально).  ",
        "- Офис в центре города.  ",
        "- График работы: с понедельника по пятницу, с 9:00 до 18:00.  ",
        "- Наставничество и профессиональное развитие от команды руководителей и специалистов.  ",
        "- Перспективы карьерного роста.  ",
        "- Уютный и светлый офис.  ",
      ],
      description:
        "Если вы увлечены качеством и хотите внести вклад в успех инновационных проектов — подавайте заявку прямо сейчас!  ",
    },
    {
      key: "net",
      title: ".NET-разработчик",
      experience: "1–3 года",
      employment: "Полная, полный рабочий день",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info: "Мы ищем талантливого .NET разработчика для работы над разнообразными проектами по индивидуальной разработке!",
      responsibilities: [
        "- Проектирование и реализация функций в соответствии с техническими требованиями.  ",
        "- Разработка и обновление систем с использованием low-code инструментов.  ",
        "- Работа с .NET, RabbitMQ, PostgreSQL и микросервисами.  ",
        "- Интеграция с ERP/CRM системами.  ",
        "- Взаимодействие с аналитиками и менеджерами проектов.  ",
        "- Написание модульных тестов и документации.  ",
      ],
      requirements: [
        "- Опыт работы не менее 2 лет.  ",
        "- Понимание работы с системами электронного документооборота и цифровой подписи будет преимуществом.  ",
        "- Умение работать в команде и желание развиваться в сфере разработки ПО.  ",
        "- Владение узбекским, английским языками, знание русского будет плюсом.  ",
      ],
      we_offer: [
        "- Официальное трудоустройство по Трудовому кодексу Республики Узбекистан.  ",
        "- Пятидневная рабочая неделя (5/2).  ",
        "- Офис в центре Ташкента с комфортными условиями работы.  ",
        "- Обучающие онлайн-курсы и дополнительные бонусы для профессионального роста.  ",
        "- Конкурентоспособная заработная плата (обсуждается на собеседовании).  ",
      ],
      description:
        "Готовы работать в команде профессионалов и создавать инновационные решения? Присоединяйтесь к нам — отправляйте своё резюме прямо сейчас!",
    },
    {
      key: "mobile",
      title: "Flutter разработчик",
      experience: "1–3 года",
      employment: "Полная, полный рабочий день  ",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info: "Присоединяйтесь к нашей команде! Мы ищем Flutter разработчика!  ",
      responsibilities: [
        "- Тесное сотрудничество с дизайнерами и менеджерами проектов.  ",
        "- Разработка кроссплатформенных приложений для iOS и Android.  ",
        "- Создание модулей приложений с нуля по макетам Figma.  ",
        "- Отладка и тестирование приложений.  ",
        "- Публикация приложений в App Store и Google Play.  ",
        "- Непрерывное улучшение качества кода.  ",
        "- Техническая поддержка, решение проблем и оптимизация приложений.  ",
        "- Написание чистого, высококачественного и организованного кода.  ",
      ],
      requirements: [
        "- Опыт разработки на Flutter не менее 2 лет.  ",
        "- Отличное знание Dart и технологий Flutter.  ",
        "- Понимание жизненного цикла мобильных приложений (от верстки до публикации).  ",
        "- Знание Provider, GetX и/или Bloc (знание GetX будет преимуществом).  ",
        "- Опыт работы с Flutter Futures, Streams, Isolates, многозадачностью, Null safety, уведомлениями, Firebase и Google Maps.  ",
        "- Ответственность и внимание к качеству проекта.  ",
        "- Опыт работы с базами данных (Floor, Hive).  ",
        "- Владение Git (Push, Pull, слияние кода, разрешение конфликтов).  ",
        "- Опыт работы с REST-сервисами.  ",
        "- Умение разбираться в чужом коде.  ",
        "- Знание шаблонов проектирования, ООП, принципов SOLID и дизайна интерфейсов.  ",
        "- Опыт управления несколькими версиями Flutter.  ",
        "- Внимание к деталям и аккуратность в организации кода.  ",
      ],
      we_offer: [
        "- Конкурентоспособную заработную плату (обсуждается индивидуально).  ",
        "- Офис в центре города.  ",
        "- График работы: с понедельника по пятницу, с 9:00 до 18:00.  ",
        "- Возможность работы над крупным проектом, который станет яркой частью вашего портфолио.  ",
        "- Профессиональную команду с дружелюбной и динамичной атмосферой.  ",
        "- Перспективы карьерного роста.  ",
      ],
      description:
        "Если вы увлечены разработкой качественных приложений и готовы работать в команде профессионалов, отправляйте своё резюме прямо сейча",
    },
    {
      key: "marketing",
      title: "Маркетолог",
      experience: "1–3 года",
      employment: "Полная, полный рабочий день  ",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info:
        "О компании\n" +
        "ООО Tafakkur Group — это команда профессионалов, работающая над инновационными проектами в сфере образования, технологий и медиа. Мы стремимся развивать интеллектуальный потенциал и критическое мышление общества. Если вы хотите стать частью динамичной и креативной команды, мы ждём вас!  ",
      responsibilities: [
        "- Разработка маркетинговой стратегии, операционного плана и бюджета проекта.  ",
        "- Анализ клиентского пути (CJM) и точек взаимодействия с аудиторией.  ",
        "- Мониторинг рынка, анализ конкурентов, сегментация аудитории и выявление потребностей.  ",
        "- Разработка УТП, позиционирования, A/B тестирование и анализ результатов.     ",
        "- Проведение фокус-групп, CustDev, выбор целевого рынка и определение Product-Market Fit.  ",
        "- Организация и обработка контента (публикации, статьи, пресс-релизы).  ",
        "- Создание и реализация рекламных кампаний, конкурсов и акций.  ",
        "- Подготовка и проведение презентаций, мероприятий и коммуникационных кампаний.  ",
        "- Взаимодействие с дизайнерами, копирайтерами и другими специалистами.  ",
        "- Разработка календарного плана публикаций и рассылок (E-mail, SMS).  ",
        "- Продвижение в соцсетях (Instagram, Facebook, LinkedIn, Twitter, ВКонтакте).  ",
        "- Настройка сквозной аналитики, анализ данных и оптимизация показателей.  ",
        "- Контроль рекламного бюджета.  ",
        "- Веб-аналитика, работа с отчетами (Google Analytics, Яндекс Метрика, UTM-метки).  ",
      ],
      requirements: [
        "- Свободное владение узбекским языком.  ",
        "- Уровень английского языка — чтение и понимание материалов.  ",
        "- Опыт работы в маркетинге от 2-х лет, желательно в B2C-сегменте.  ",
        "- Образование в сфере маркетинга (высшее или курсы).  ",
        '- Опыт выполнения задач, указанных в разделе "Обязанности".  ',
        "- Развитые коммуникативные навыки.",
        "- Аналитическое мышление.  ",
      ],
      we_offer: [
        "- Конкурентоспособную заработную плату (обсуждается индивидуально).  ",
        "- Офис в центре города.  ",
        "- График работы: с понедельника по пятницу, с 9:00 до 18:00.  ",
        "- Перспективы карьерного роста.  ",
        "- Уютный и светлый офис.  ",
        "- Дружная и креативная команда.  ",
      ],
      description:
        "Если вы хотите развиваться в сфере маркетинга и воплощать интересные идеи в жизнь, отправляйте своё резюме прямо сейчас!",
    },
    {
      key: "frontend",
      title: "Фронтенд разработчик",
      experience: "1–3 года",
      employment: "Полная, полный рабочий день  ",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info:
        "О компании\n" +
        "ООО Tafakkur Group — динамично развивающаяся компания в сфере IT и разработки программного обеспечения. Мы создаём современные цифровые продукты и ищем талантливого Frontend разработчика (React.js) в свою команду!  ",
      responsibilities: [
        "- Разработка и поддержка фронтенд-части веб-приложений на React.js.  ",
        "- Создание динамических пользовательских интерфейсов с использованием JavaScript.  ",
        "- Вёрстка по макетам (Figma) с адаптацией под разные браузеры (IE11, Chromium, Safari) и устройства (мобильные, планшеты).  ",
        "- Интеграция с backend-сервисами через REST API.  ",
        "- Тестирование, внедрение и сопровождение разработанных решений.  ",
        "- Оптимизация производительности и улучшение пользовательского опыта.  ",
      ],
      requirements: [
        "- Опыт работы во frontend-разработке от 2-х лет.  ",
        "- Отличное знание HTML5, CSS3/Sass, Bootstrap 5, Tailwind.  ",
        "- Уверенные навыки работы с JavaScript и React.js.  ",
        "- Опыт работы с макетами в Figma и их адаптивной вёрсткой.",
        "- Понимание принципов клиент-серверного взаимодействия и работы с REST API.  ",
        "- Опыт кроссбраузерной и адаптивной вёрстки.",
        "- Знание систем контроля версий (Git).  ",
        "- Умение работать в команде, внимание к деталям и высокая ответственность.  ",
      ],
      we_offer: [
        "- Конкурентоспособную заработную плату (обсуждается индивидуально).  ",
        "- Офис в центре города.  ",
        "- График работы: с понедельника по пятницу, с 9:00 до 18:00.  ",
        "- Перспективы карьерного роста.  ",
        "- Уютный и светлый офис.  ",
      ],
      description:
        "Если вы хотите работать над интересными проектами в сфере веб-разработки и развиваться вместе с нами, отправьте своё резюме прямо сейчас!",
    },
    {
      key: "layout-developer",
      title: "Верстальщик",
      experience: "1–3 года",
      employment: "Полная, полный рабочий день",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info:
        "О компании\n" +
        "ООО Tafakkur Group — современная IT-компания, занимающаяся разработкой и поддержкой высококачественных цифровых продуктов. Мы ищем талантливого \n" +
        "\n" +
        "Frontend разработчика / Верстальщика, готового присоединиться к нашей профессиональной команде и развиваться вместе с нами!",
      responsibilities: [
        "- Верстка дизайн-макетов быстро и качественно.  ",
        "- Разработка адаптивных и кроссбраузерных интерфейсов.",
        "- Работа в команде по методологии Agile.  ",
        "- Интеграция фронтенд-части с бэкендом через API.  ",
        "- Оптимизация производительности и обеспечение высокой скорости загрузки страниц.  ",
        "- Тестирование и устранение багов.  ",
      ],
      requirements: [
        "- Опыт коммерческой разработки от 2 лет.  ",
        "- Отличное знание HTML5, CSS3 и JavaScript.  ",
        "- Владение CSS-препроцессорами (Sass, LESS).  ",
        "- Опыт работы с Git (commit, push, pull).  ",
        "- Знание методологии БЭМ и принципов семантической вёрстки.  ",
        "- Умение верстать pixel-perfect по макетам (Figma).  ",
        "- Опыт создания адаптивной и кроссбраузерной вёрстки.  ",
        "- Ответственность, внимательность к деталям и высокая организованность.  ",
      ],
      we_offer: [
        "- Конкурентоспособную заработную плату (обсуждается индивидуально).  ",
        "- Офис в центре города.  ",
        "- График работы: с понедельника по пятницу, с 9:00 до 18:00.  ",
        "- Наставничество и профессиональное развитие от команды руководителей и специалистов.  ",
        "- Перспективы карьерного роста.  ",
        "- Уютный и светлый офис.  ",
      ],
      description:
        "Если вы хотите работать над интересными проектами и стать частью нашей команды, отправьте своё резюме прямо сейчас!",
    },
    {
      key: "pm",
      title: "Менеджер проекта в ИТ",
      experience: "1–3 года",
      employment: "Полная, полный рабочий день  ",
      working_type: "5/2",
      working_hours: "8 часов в день",
      work_format: "На территории работодателя",
      info:
        "О компании\n" +
        "ООО Tafakkur Group — динамично развивающаяся IT-компания, специализирующаяся на разработке инновационных цифровых решений. Мы ищем опытного Project Manager'а, готового взять под контроль управление проектами на всех этапах разработки и внедрения.  ",
      responsibilities: [
        "- Самостоятельное управление новыми проектами на всех стадиях, внедрение эффективных процессов управления.  ",
        "- Определение видения продукта, разработка и защита стратегии и дорожной карты проекта.  ",
        "- Создание требований к проектам и задачам.  ",
        "- Управление и приоритизация задач в бэклоге.  ",
        "- Организация командных встреч, ведение протоколов и контроль выполнения задач.  ",
        "- Контроль качества и соблюдения сроков выполнения проекта.  ",
        "- Проведение маркетинговых исследований и анализ конкурентной среды.",
      ],
      requirements: [
        "- Опыт работы в управлении проектами не менее 3 лет.  ",
        "- Базовые знания front-end и back-end (HTML, CSS, JavaScript, MySQL и др.).  ",
        "- Опыт разработки проектных процессов и оценивания задач.  ",
        "- Умение расставлять приоритеты и работать в условиях сжатых сроков.  ",
        "- Знание инструментов управления проектами (Trello, JIRA и др.).  ",
        "- Понимание принципов работы веб- и мобильных технологий.  ",
        "- Навыки работы в многозадачном режиме.  ",
        "- Отличные коммуникативные и управленческие навыки.  ",
        "- Владение узбекским и русским языками на уровне делового общения.  ",
      ],
      we_offer: [
        "- Конкурентоспособную заработную плату (обсуждается индивидуально).  ",
        "- Офис в центре города.  ",
        "- График работы: с понедельника по пятницу, с 9:00 до 18:00.  ",
        "- Наставничество и профессиональное развитие от команды руководителей и специалистов.  ",
        "- Перспективы карьерного роста.  ",
        "- Уютный и светлый офис.  ",
      ],
      description:
        "Если вы готовы развиваться вместе с нами и реализовывать интересные проекты, отправьте своё резюме прямо сейчас! Мы ждём вас в нашей команде!",
    },
  ];
  const selectedData = data.find((item) => item.key === key);
  return (
    <div className="single-vacancy">
      <div className="main-title">
        <div className="line" />
        <div className="text">
          <AnimatedText text={selectedData.title} />
        </div>
      </div>
      <div className="vacancy-detail">
        <span>Опыт работы:</span> <span>{selectedData.experience}</span>
      </div>

      <div className="vacancy-detail">
        <span>Занятость:</span> <span>{selectedData.employment}</span>
      </div>

      <div className="vacancy-detail">
        <span>График работы:</span> <span>{selectedData.working_type}</span>
      </div>

      <div className="vacancy-detail">
        <span>Рабочие часы:</span> <span>{selectedData.working_hours}</span>
      </div>

      <div className="vacancy-detail">
        <span>Формат работы:</span> <span>{selectedData.work_format}</span>
      </div>

      <div className="vacancy-detail mb-40">
        <span /> <span>{selectedData.info}</span>
      </div>

      <div className="vacancy-detail mb-40">
        <span>Требования:</span>{" "}
        <span>
          {selectedData.requirements.map((item) => (
            <div>{item}</div>
          ))}
        </span>
      </div>

      <div className="vacancy-detail mb-40">
        <span>Мы предлагаем:</span>{" "}
        <span>
          {selectedData.we_offer.map((item) => (
            <div>{item}</div>
          ))}
        </span>
      </div>

      <div className="vacancy-detail mb-40">
        <span>Обязанности:</span>{" "}
        <span>
          {selectedData.responsibilities.map((item) => (
            <div>{item}</div>
          ))}
        </span>
      </div>

      <div className="vacancy-detail">
        <span /> <span>{selectedData.description}</span>
      </div>

      <div className="send-btn">
        <Link target="_blank" rel="noopener noreferrer" href="https://t.me/Thinkland_HR">
          <button className="text-flip" type="button">
            <div className="text-flip-content vertical">
              <div className="text">Откликнуться</div>
              <div className="hover-text">Откликнуться</div>
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default transition(SingleVacancy);

import { Link } from "react-router-dom";

import telegram from "../../assets/social/telegram.svg";
import instagram from "../../assets/social/instagram.svg";
import facebook from "../../assets/social/facebook.svg";
import linkedin from "../../assets/social/linkedin.svg";
import "./footer.scss";

function Footer({ setIsModalOpen }) {
  return (
    <div id="footer" className="footer-component">
      <div className="footer-content">
        <div className="info-section">
          <div className="title">Воплощаем идеи в цифровые решения</div>
          <Link to="tel:+998948147777">
            <button
              // onClick={() => setIsModalOpen(true)}
              className="order-button text-flip"
              type="button"
              variant="light"
            >
              <div class="text-flip-content vertical">
                <div class="text">Заказать проект</div>
                <div class="hover-text">Заказать проект</div>
              </div>
            </button>
          </Link>
        </div>
        <div className="footer-links">
          <ul>
            <li>
              <Link to="/#about_us">
                <div className="text-flip">
                  <div class="text-flip-content vertical">
                    <div class="text">О нас</div>
                    <div class="hover-text">О нас</div>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/vacancies">
                <div className="text-flip">
                  <div class="text-flip-content vertical">
                    <div class="text">Вакансии</div>
                    <div class="hover-text">Вакансии</div>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/#portfolio">
                <div className="text-flip">
                  <div class="text-flip-content vertical">
                    <div class="text">Портфолио</div>
                    <div class="hover-text">Портфолио</div>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="contacts">
          <ul>
            <li>
              <Link to="tel:+998948147777">
                <div className="text-flip">
                  <div class="text-flip-content vertical">
                    <div class="text">+998 94 814 77 77</div>
                    <div class="hover-text">+998 94 814 77 77</div>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="https://yandex.uz/maps/-/CDxQfUM3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text-flip">
                  <div class="text-flip-content vertical">
                    <div class="text">Г. Ташкент ул. Асака 11</div>
                    <div class="hover-text">Г. Ташкент ул. Асака 11</div>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
          <p></p>
        </div>
      </div>
      <div className="footer-container">
        <div className="footer-bottom">
          <div className="comp-info">
            <p>
              © 2024 THINKLAND <br /> Все права защищены
            </p>
            <p>OOO «TAFAKKUR GROUP» </p>
          </div>
          <div className="social-section">
            <ul>
              <li>
                <Link to="https://t.me/thinklandco/" target="_blank" rel="noopener noreferrer">
                  <div className="text-flip">
                    <div class="text-flip-content vertical">
                      <div class="text">
                        <img src={telegram} alt="telegram img" />
                      </div>
                      <div class="hover-text">
                        <img src={telegram} alt="telegram img" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/thinklandco/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-flip">
                    <div class="text-flip-content vertical">
                      <div class="text">
                        <img src={instagram} alt="telegram img" />
                      </div>
                      <div class="hover-text">
                        <img src={instagram} alt="telegram img" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.facebook.com/thinklandco/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-flip">
                    <div class="text-flip-content vertical">
                      <div class="text">
                        <img src={facebook} alt="telegram img" />
                      </div>
                      <div class="hover-text">
                        <img src={facebook} alt="telegram img" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/thinklandco/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-flip">
                    <div class="text-flip-content vertical">
                      <div class="text">
                        <img src={linkedin} alt="telegram img" />
                      </div>
                      <div class="hover-text">
                        <img src={linkedin} alt="telegram img" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
